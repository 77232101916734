import React from 'react'

const Header = props => (
  <header>
    <div id="name">
      <div className="column">
        <h1>{props.site_title}</h1>
      </div>
      <div className="column" />
      <div className="column last" />
    </div>
    <section>
      <div className="row">
        <div
          className="doubleColumn intro"
          dangerouslySetInnerHTML={{ __html: props.intro_paragraph_html }}
        />
        <div className="column last">
          <p className="contactLabel">Email</p>
          <p className="contactInfo">
            <a href={`mailto:${props.betsy_email}`}>{props.betsy_email}</a>
          </p>
          <p className="contactLabel">Twitter</p>
          <p className="contactInfo">
            <a href={`http://www.twitter.com/${props.betsy_twitter}`}>
              {'@' + props.betsy_twitter}
            </a>
          </p>
        </div>
      </div>
    </section>
  </header>
)

export default Header
