import React from "react";
import Link from "./Link";
import { writingLinks } from "../writingItems";

const WritingSection = props => (
  <section id="writing">
    <div className="writingRow">
      <div className="column">
        <h2>{props.writing_section_heading}</h2>
      </div>
      <div className="doubleColumn">
        {writingLinks.map((link, i) => {
          return (
            <Link
              key={i}
              title={link.title}
              url={link.url}
              description={link.description}
              publication={link.publication}
            />
          );
        })}
      </div>
    </div>
  </section>
);

export default WritingSection;
