import React from 'react'
import Header from './Header'
import WritingSection from './WritingSection'

import './style.scss'

const Home = props => (
  <div>
    <div className="row purple">
      <div className="stripeRow">
        <div className="column">
          <div id="stripeLeft" />
        </div>
      </div>
    </div>
    <Header
      site_title={props.site_title}
      intro_paragraph_html={props.intro_paragraph_html}
      betsy_email={props.betsy_email}
      betsy_twitter={props.betsy_twitter}
    />
    <WritingSection writing_section_heading={props.writing_section_heading} />
    <footer />
  </div>
)

export default Home
