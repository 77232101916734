import React from 'react'

export default class Link extends React.Component {
  render() {
    const publication_suffix = this.props.publication ? (
      <span className="publication">— {this.props.publication}</span>
    ) : null

    const description = this.props.description ? (
      <p>{this.props.description}</p>
    ) : null

    return (
      <div>
        <p className="link">
          <a href={this.props.url}>{this.props.title}</a> {publication_suffix}
        </p>
        {description}
      </div>
    )
  }
}
