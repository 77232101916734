import React from "react";
import Helmet from "react-helmet";
import marked from "marked";
import Homepage from "../components/Homepage";

export default class IndexPage extends React.Component {
  render() {
    const node = this.props.data.allSrcJson.edges[0].node;
    return (
      <>
        <Helmet title={"Betsy Morais"} />
        <Homepage
          site_title={node.site_title}
          intro_paragraph_html={marked(node.intro_paragraph)}
          betsy_email={node.betsy_email}
          betsy_twitter={node.betsy_twitter}
          writing_section_heading={node.writing_section_heading}
        />
      </>
    );
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    allSrcJson {
      edges {
        node {
          site_title
          intro_paragraph
          betsy_email
          betsy_twitter
          writing_section_heading
        }
      }
    }
  }
`;
