export const writingLinks = [
  {
    title: "Mascot Love",
    url:
      "https://www.newyorker.com/magazine/2019/12/02/now-and-forever-with-a-giant-baseball-for-a-head",
    publication: "The New Yorker",
    description:
      "Mrs. Met hosts a wedding expo for die-hard fans and their betrotheds."
  },
  {
    title: "Ballet Kink",
    url:
      "https://www.newyorker.com/magazine/2019/05/20/ballet-kink-at-the-guggenheim",
    publication: "The New Yorker",
    description:
      "Brendan Fernandes drills dancers in rope bondage and consent at the Guggenheim."
  },
  {
    title: "Debrief",
    url:
      "https://www.newyorker.com/magazine/2018/07/09/scholarly-advice-for-dark-times",
    publication: "The New Yorker",
    description:
      "Professor Eugene Thacker teaches students at the New School the philosophy of pessimism."
  },
  {
    title: "Getting In",
    url:
      "https://www.newyorker.com/magazine/2018/04/23/helping-foreign-artists-cut-through-red-tape-pro-bono",
    publication: "The New Yorker",
    description:
      "Helping foreign artists cut through the visa system’s red tape."
  },
  {
    title: "A Workspace Built for Women",
    url:
      "https://www.theatlantic.com/business/archive/2018/04/an-architect-for-the-feminist-moment/557516/",
    publication: "The Atlantic",
    description: "A profile of the architect Alda Ly."
  },
  {
    title: "Interpretive Protest",
    url: "https://www.newyorker.com/magazine/2018/02/12/interpretive-protest",
    publication: "The New Yorker",
    description: "Dress rehearsal for the resistance."
  },
  {
    title: "Breaking Into Baseball’s Ultimate Boys’ Club",
    url:
      "http://www.theatlantic.com/magazine/archive/2016/09/the-girl-of-summer/492737",
    publication: "The Atlantic",
    description: "A profile of Jessica Mendoza."
  },
  {
    title: "Underage",
    url: "http://www.newyorker.com/magazine/2015/09/07/underage",
    publication: "The New Yorker",
    description:
      "Roving around the Upper East Side with the sixteen year-old girl who serves on the local community board."
  },
  {
    title: "Spreading the Word",
    url:
      "http://www.newyorker.com/magazine/2015/10/05/spreading-the-word-up-lifes-ladder-betsy-morais",
    publication: "The New Yorker",
    description: "Red Hook kids try old-school marketing."
  },
  {
    title: "Niche Product",
    url: "http://www.newyorker.com/magazine/2015/08/31/niche-product",
    publication: "The New Yorker",
    description: "On underwear, feminism, and photoshoots."
  },
  {
    title: "Wily",
    url: "http://www.newyorker.com/magazine/2015/04/13/wily",
    publication: "The New Yorker",
    description: "The coyote catchers of New York City."
  },
  {
    title: "Iron Mayor",
    url: "http://www.newyorker.com/magazine/2015/04/06/iron-mayor",
    publication: "The New Yorker",
    description: "Riding bikes with Steven Fulop, the mayor of Jersey City."
  },
  {
    title: "Ladies Who Cab",
    url: "http://www.newyorker.com/business/currency/ladies-cab",
    publication: "The New Yorker",
    description: "Riding in taxis with female drivers."
  },
  {
    title: "Make Room",
    url: "http://www.newyorker.com/business/currency/buying-breather",
    publication: "The New Yorker",
    description: "An interview with the man who rents rooms by the hour."
  },
  {
    title: "President Tong and His Disappearing Islands",
    url:
      "http://www.newyorker.com/tech/elements/president-tong-and-his-disappearing-islands",
    publication: "The New Yorker",
    description:
      "The leader of a small island nation tries in vain to save his country from being completely flooded."
  },
  {
    title: "Serving Time, At Home with the Kids",
    url: "http://bklynr.com/serving-time-at-home-with-kids/",
    publication: "Bklynr",
    description: "Looking past incarceration for women in Brooklyn."
  },
  {
    title: "Russian Stew",
    url: "http://bklynr.com/russian-stew",
    publication: "Bklynr",
    description:
      "Sweating at the Mermaid Spa in Sea Gate, Brooklyn’s oldest gated community."
  }
];
